import { userEventRegister } from "Features/Analytics/events";
import get from "lodash/get";
import { v4 as uuidv4 } from "uuid";
import useAsyncTask from "../../Hooks/useAsyncTask";
import useToastMessage from "../../Hooks/useToastMessage";
import { TEvent } from "../../Models/Events/@types";
import { TRegistrant } from "../../Models/Registrant/@types";
import { TUser } from "../../Models/User/@types";
import { useStoreActions } from "../../Stores";
import { useStoreState } from "../../Stores/index";
import utils from "../../Utils/index";
import Campaign from "../Campaign/Campaign";

declare global {
    interface Window {
        fbq: any;
    }
}

export const useRegistrationForm = (eventId: string, agencyId: string = "") => {
    const withToast = useToastMessage();
    const { user, eventDetails } = useStoreState(({ Events: { eventDetails }, User: { user } }) => ({ user, eventDetails }));
    const { registerSingleRegistrant, setRegistrant, setUser } = useStoreActions(({ Registrant: { registerSingleRegistrant, setRegistrant }, User: { setUser } }) => ({ registerSingleRegistrant, setRegistrant, setUser }));
    const handleSubmit = useAsyncTask(async (registrant: Partial<TRegistrant>) => {
        const UUID = utils.getCookie("UUID");
        if (!UUID) utils.setCookie("UUID", uuidv4());
        const data = { ...registrant, agencyId };

        return await withToast(
            async () => {
                const campaign = Campaign.fromCookie();
                try {
                    const res = await registerSingleRegistrant({ eventId, utm: campaign.campaignData, registrant: data });
                    userEventRegister(res, campaign.campaignData);
                    Campaign.clearCampaignCookie();
                    setRegistrant(res as TRegistrant);
                    setUser({ ...user, ...registrant } as TUser);
                } catch (err) {
                    const error = get(err, "response.data.error");
                    if (error?.error && error.error?.code === "REQUIRED_FIELD_NOT_FOUND" && error.error?.info?.includes("invitationReferenceNumber")) {
                        console.log(error.error);
                        throw new Error("You are not allowed to register on the event.");
                    } else {
                        throw err;
                    }
                }
            },
            {
                successToastMessage: getSuccessTextMessage(user, eventDetails),
                toastDuration: 10000,
            }
        );
    });

    return {
        handleSubmit,
    };
};

const getSuccessTextMessage = (user?: TUser, event?: TEvent) => {
    const successToast = "Registration Successful!";
    const emailSentText = `An email with your registration details has been sent to ${user?.email}.`;
    const waitlistText = "You are on our waitlist.";
    const verificationEmailSentText = `We've also sent an email to verify your account. Please click on the link in the email to complete verification.`;
    const checkJunkText = `Please check your Spam or Junk folder in-case you have not received the email in your Inbox.`;

    if (!user || !event) return `${successToast} ${emailSentText} ${checkJunkText}`;

    if (!user.emailVerified && !event._settings?.approvalRequired) return `${successToast} ${emailSentText} ${verificationEmailSentText} ${checkJunkText}`;

    if (!user.emailVerified && event._settings?.approvalRequired) return `${successToast} ${waitlistText} ${emailSentText} ${verificationEmailSentText} ${checkJunkText}`;

    if (user.emailVerified && event._settings?.approvalRequired) return `${successToast} ${waitlistText} ${emailSentText} ${checkJunkText}`;

    return `${successToast} ${emailSentText} ${checkJunkText}`;
};
